<template>
    <div>
        <nav class="nav_side col-lg-2 col-md-3 col-sm-3"></nav>
        <main class="col-lg-10 col-md-9 col-sm-9 left_gutter main fill">
            <h1>Purchase Order Receiving</h1>
            <div class="panel panel-default">
                <div class="panel-heading"></div>
                <div class="row panel-body">
                    <form>
                        <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                            <label>Category</label>
                            <select class="form-control" v-model="formFields.materialID.val" @change="search">
                                <option value="0">All</option>
                                <option v-for="[materialID, material] in cache.materialCache.MATERIAL_CATEGORIES" v-bind:key="materialID" :value="materialID">{{ material }}</option>
                            </select>
                        </div>
                        <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                            <label>Location</label>
                            <select class="form-control" v-model="formFields.cdiLocationID.val" @change="search">
                                <option value="0">All</option>
                                <option v-for="[cdiLocationID, cdiLocation] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="cdiLocationID" :value="cdiLocationID">{{ cdiLocation }}</option>
                            </select>
                        </div>
                    </form>
                </div>
            </div>
    
            <div v-show="receivingForm">
                <form>
                    <h3>{{ receiveFormTitle }}</h3>
                    <div class="form-group col-md-3 col-sm-4 col-xs-6">
                        <label>User #</label><input type="text" class="form-control" v-model="receiveFormFields.userID.val">
                    </div>
                    <div class="form-group col-md-3 col-sm-4 col-xs-6">
                        <label>Receive Qty</label><input type="text" class="form-control" v-model="receiveFormFields.receivedQty.val">
                    </div>
                    <div class="form_grid col-md-3 col-sm-4 col-xs-6">
                        <input type="button" value="Receive" class="cdi_button btn btn-default" @click="receivedItem">
                        <input type="button" value="Cancel" class="cdi_button btn btn-default" @click="cancelForm">
                    </div>
                </form>
            </div>
            <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th>PO</th>
                    <th>Vendor</th>
                    <th>Item</th>
                    <th>ETA</th>
                    <th>QTY</th>
                    <th>Rec</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(po, id) in results" v-bind:key="id" @click="showReceiving(po)">
                    <td><router-link :to="`/po/${po.purchase_order_id}`">{{ po.purchase_order_id }}</router-link></td>
                    <td>{{ po.company_name }}</td>
                    <td>{{ po.raw_material }}</td>
                    <td>{{ po.purchase_order_eta_date }}</td>
                    <td>{{ po.ordered_qty }}</td>
                    <td>{{ po.total_received_qty }}</td>
                </tr>
                </tbody>
            </table>
            </div>
        </main>
    </div>
</template>

<script>
import materialCache from '@/cache/material.cache';
import cdiLocationCache from '@/cache/cdiLocation.cache';
import helpers from "@/store/helpers";
import { store } from "@/store/Tool.store";
export default {
    name: "POReceiving",
    data() {
        return {
            showForm: false,
            formFields: {
                materialID: {val: 4, qsField: '[numbers][m.inventory_category_id]'},
                cdiLocationID: {val: 1, qsField: '[numbers][po.cdi_location_id]'},
                json: {val: 1, qsField: '[json]'},
            },
            receiveFormFields: {
                userID: {val: this.$appStore.state.user.userID, qsField: '[user_id]'},
                receivedQty: {val: 0, qsField: '[received_qty]'},
                rawMaterialID: {val: 0, qsField: '[raw_material_id]'},
            },
            purchaseOrderID: 0,
            receiveFormTitle: 'Receive',
            cache: {
                materialCache,
                cdiLocationCache,
            },
            results: {},
            receivingForm: false,
        }
    },
    created() {
        this.search();
    },
    methods: {
        search: function() {
            store.api('/po/po_receiving_list/', helpers.getQSPairs(this.formFields))
                .then(results => this.results = results);
        },
        showReceiving: function(po) {
            this.receiveFormFields.receivedQty.val = (po.ordered_qty - po.total_received_qty);
            this.receiveFormFields.rawMaterialID.val = po.raw_material_id;
            this.purchaseOrderID = po.purchase_order_id;
            this.receiveFormTitle = po.raw_material;
            this.receivingForm = true;
        },
        receivedItem: function() {
            store.api('/po/received_item/', {...{ c_id: this.purchaseOrderID }, ...helpers.getQSPairs(this.receiveFormFields), ...helpers.getQSPairs(this.formFields)})
                .then(results => this.results = results);
            this.cancelForm();
        },
        cancelForm: function() {
            this.receivingForm = false;
            this.receiveFormFields.receivedQty.val = 0;
            this.receiveFormFields.rawMaterialID.val = 0;
            this.purchaseOrderID = 0;
            this.receiveFormTitle = 'Receive';
        }

    },
}
</script>

<style scoped>

</style>