export default {
    MATERIAL_CATEGORIES: [
        [5, 'Angle Iron/Tubing/Rods'],
        [6, 'Hardware'],
        [12, 'Damper'],
        [13, 'Duct Pack'],
        [7, 'Insulating Inventory'],
        [10, 'ISO Rails'],
        [16, 'Janitorial'],
        [8, 'Lumber'],
        [1, 'Shop Supplies/ Small Tools'],
        [9, 'Office Supplies'],
        [2, 'Plasma Inventory'],
        [14, 'Screens'],
        [15, 'Machine Repair and Maintenance'],
        [4, 'Metal'],
        [11, 'Turret Tools'],
        [3, 'Welding Inventory']
    ],
    Material_Measurement_Units:[

        ['Annual', 'Annual'],
        ['Bag', 'Bag'],
        ['Bottle', 'Bottle'],
        ['Box', 'Box'],
        ['Carton',	'Carton'],
        ['Cartridge', 'Cartridge'],
        ['Case', 'Case'],
        ['Cases', 'Cases'],
        ['Cubic Foot', 'Cubic Foot'],
        ['Cylinder', 'Cylinder'],
        ['Days', 'Days'],
        ['Dollars', 'Dollars'],
        ['Dozen', 'Dozen'],
        ['Drum', 'Drum'],
        ['Each', 'Each'],
        ['Feet', 'Feet'],
        ['FTE', 'Full Time Employee'],
        ['Gallon', 'Gallon'],
        ['Gross', 'Gross'],
        ['Hours', 'Hours'],
        ['Hundred', 'Hundred'],
        ['Items', 'Items'],
        ['Kit', 'Kit'],
        ['Lot', 'Lot'],
        ['Monthly', 'Monthly'],
        ['Net Ton', 'Net Ton'],
        ['Pack', 'Pack'],
        ['Packages', 'Packages'],
        ['Pad', 'Pad'],
        ['Pail', 'Pail'],
        ['Pair', 'Pair'],
        ['Pallet',	'Pallet'],
        ['Piece',	'Piece'],
        ['Pint', 'Pint'],
        ['Pounds', 'Pounds'],
        ['Ream', 'Ream'],
        ['Roll', 'Roll'],
        ['Set', 'Set'],
        ['Sheet', 'Sheet'],
        ['Square Ft', 'Square Foot'],
        ['Stick', 'Stick'],
        ['Thousand', 'Thousand'],
        ['Tube', 'Tube'],
        ['Unit', 'Unit'],
        ['Value', 'Value'],
        ['Yard', 'Yard'],
    ],

}